<template>
  <v-container id="settings" fluid tag="section" class="container-full-size">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="secondary">
          <template #heading>
            <div class="display-2 font-weight-light">
              {{ str['change_settings'] }}
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col v-for="field in fields" :key="field.id" cols="12" class="mt-4">
                  <div v-if="field.parentTitle" style="margin-bottom: 10px; font-weight: bold">
                    {{ field.parentTitle }}
                  </div>
                  <v-text-field
                    v-if="field.type !== 'checkbox' && field.type !== 'password'"
                    v-model="field.value"
                    :label="field.title"
                    class="purple-input"
                    :type="field.type"
                  />
                  <v-text-field
                    v-if="field.type === 'password'"
                    v-model="field.value"
                    :label="field.title"
                    class="purple-input"
                    :append-icon="showPasssword[field.id] ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPasssword[field.id] ? 'text' : 'password'"
                    @click:append="showPasssword[field.id] = !showPasssword[field.id]"
                  />
                  <v-checkbox
                    v-if="field.type === 'checkbox'"
                    v-model="field.value"
                    :label="field.title"
                    class="form-field-checkbox mt-0"
                    hide-details
                  />
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn color="success" class="mr-0" @click="save()">
                    {{ str['save'] }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import axios from 'axios'

export default {
  data() {
    return {
      str: window.strings,
      user: Utils.getUser(),
      settings: [],
      fields: [],
      showPasssword: {},
    }
  },
  beforeMount: function () {
    this.main()
  },
  methods: {
    main: function () {
      if (!this.user) {
        return false
      }
      this.setFields()
      this.setSettings()
      if (!window.useResetCacheSockets) {
        Utils.removeRequestCache('settings')
      }
    },
    setFields: function () {
      const fields =
        this.user && this.user.configurations && this.user.configurations.settings
          ? JSON.parse(JSON.stringify(this.user.configurations.settings))
          : []
      const showPasssword = {}
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].parentTitle) {
          fields[i].parentTitle = window.strings[fields[i].parentTitle] ? window.strings[fields[i].parentTitle] : fields[i].parentTitle
        }
        if (fields[i].title) {
          fields[i].title = window.strings[fields[i].title] ? window.strings[fields[i].title] : fields[i].title
        }
        if (fields[i].type === 'password') {
          showPasssword[fields[i].id] = false
        }
      }
      this.showPasssword = showPasssword
      this.fields = fields
    },
    setSettings: function () {
      const self = this
      this.$isLoading(true)
      Api.getSettings(
        {
          id: axios.defaults.ptId,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.settings = response.data
            self.setData()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    back: function () {
      this.$router.goBack()
    },
    setData: function () {
      const userNotes = this.getUserNotes()
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].notes) {
          if (userNotes && userNotes[this.fields[i].id]) {
            this.fields[i].value = userNotes[this.fields[i].id]
          }
        } else {
          for (let s = 0; s < this.settings.length; s++) {
            if (this.fields[i].id === this.settings[s].name) {
              if (this.fields[i].type === 'checkbox') {
                this.fields[i].value = this.settings[s].value === '1' ? true : false
              } else {
                this.fields[i].value = this.settings[s].value
              }
              break
            }
          }
        }
      }
    },
    save: function () {
      const self = this
      const notes = {}
      this.$isLoading(true)

      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].notes) {
          notes[this.fields[i].id] = this.fields[i].value
        } else {
          for (let s = 0; s < this.settings.length; s++) {
            if (this.fields[i].id === this.settings[s].name) {
              if (this.fields[i].type === 'checkbox') {
                this.settings[s].value = this.fields[i].value ? '1' : '0'
              } else {
                this.settings[s].value = this.fields[i].value
              }
              break
            }
          }
        }
      }

      Api.updateSettings(
        {
          id: axios.defaults.userDbId,
          settings: this.settings,
        },
        function (response) {
          if (response.success) {
            if (Object.keys(notes).length) {
              self.saveNotes(notes, function () {
                self.$isLoading(false)
                self.back()
              })
            } else {
              self.$isLoading(false)
              self.back()
            }
          } else {
            self.$isLoading(false)
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    saveNotes: function (notes, callback) {
      const self = this
      if (this.user && notes) {
        const userNotes = this.getUserNotes()
        for (const key in notes) {
          userNotes[key] = notes[key]
        }
        return Api.updateUser(
          {
            notes: JSON.stringify(userNotes),
          },
          function (response) {
            if (response.success) {
              self.user.notes = response.data.notes
              Utils.setStorage('user', self.user)
              callback()
            } else {
              self.$isLoading(false)
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        callback()
      }
    },
    getUserNotes: function () {
      let userNotes = {}
      try {
        if (this.user.notes) {
          userNotes = JSON.parse(this.user.notes)
        }
      } catch {}
      return userNotes
    },
  },
}
</script>

<style scoped>
.back-button {
  margin-bottom: 20px;
}
</style>
